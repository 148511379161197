module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":640},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"voidcluster","short_name":"voidcluster","icons":[{"src":"/favicons/voidcluster-i-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/voidcluster-i-512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","background_color":"#fff","theme_color":"#005864","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    }]
